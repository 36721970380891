/** @format */

import React from "react";
import HeroBanner from "../../components/HeroGallery/HeroBanner";
import InfoSection from "../../components/InfoSection/InfoSection";
import "./Home.scss";
function Home() {
	return (
		<div className='home'>
			<HeroBanner />
			<p>Sito in costruzione</p>
			<p>Ci scusiamo per il disagio, presto saremo online</p>
		</div>
	);
}

export default Home;
