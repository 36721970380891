/** @format */

import React from "react";
import "./Bubletip.scss";

interface ITooltip {
	title: string;
	description: string;
}

function BubleTip({ title, description }: ITooltip) {
	return (
		<div className='bubleContainer'>
			<p className='bubleTitle'>{title}</p>
			<div className='bubleTooltip'>
				<h2>{title}</h2>
				<div className='line'></div>
				<p>{description}</p>
			</div>
		</div>
	);
}
export default BubleTip;
