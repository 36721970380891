/** @format */

import React from "react";
import "./About.scss";
import BubleTip from "../../components/BubleTip/Bubletip";
function About() {
	return (
		<div className='aboutPage'>
			<BubleTip
				title='Formulanatura'
				description='Formulanatura è una nuova realtà nel campo della Dermocomesi
					funzionale e dell’Integrazione Alimentare, nata dalla volontà di
					professionisti provenienti dal mondo farmaceutico e dermocosmetico
					italiano, dopo esperienze trentennali trascorsi al servizio di
					primarie aziende multinazionali.'
			/>
			<BubleTip
				title='Obiettivo'
				description='L’obiettivo principale che ci siamo posti è quello di ricercare in ogni prodotto presentato la salute e il benessere psico-fisico a 360 gradi, sia attraverso creme dermocosmetiche che Integratori Alimentari specifici.'
			/>
			<BubleTip
				title='Prodotti'
				description='Tutti i nostri prodotti, pertanto, prevedono l’utilizzo di principi attivi provenienti dalla natura sottoforma di estratti titolati e standardizzati di piante officinalis, coltivate in regime di agricoltura biologica e, quindi, privi di pesticidi e xenobiotici e di sostanze naturali approvate dal Ministero della Salute.'
			/>
			<BubleTip
				title='Piante'
				description='Utilizziamo piante che sono raccolte nel loro specifico tempo balsamico e sottoposte a processi di essiccazione naturale, garantiti da processi controllati e standardizzati, applicando procedure rigorose in linea con i migliori standard internazionali.'
			/>
			<BubleTip
				title='Ricerca'
				description='Il nostro centro di ricerca utilizza gli Ultrasuoni e lo Spray dry per ottenere estratti (full spectrum) a titolo noto, abbinati a tecnologie liposomiali, sia per i prodotti dermocosmetici che Integratori, per ottenere principi attivi, ad attività farmacologica molto superiore a quelli ottenuti in modo industriale e tutti dotati di una biodisponibilità elevata, che ne garantisce un’attività eccezionale.'
			/>
			<BubleTip
				title='Università'
				description='Collaboriamo con l’Università di Roma (La sapienza) e Tor Vergata per l’attività di ricerca sia in campo dermocosmetico che di Integrazione Alimentare e per la preparazione di dossier e di Medical Device di varie classi '
			/>
			<BubleTip
				title='Cosmetici'
				description='Utilizziamo solo materie prime rinnovabili di origine vegetale o naturale.
Non utilizziamo prodotti derivati dal petrolio, siliconi e conservanti aggressivi.
I nostri prodotti non sono testati su animali.
Non utilizziamo Peg e Parabeni come conservant,i ma miscele da noi approntate e previste dalla presente normativa.
Non utilizziamo SLG (sodio lautil solfato) SLES, MEA, DEA, TEA.
Non utilizziamo prodotti che rilasciano formaldeide.
No OGM.
'
			/>
			<BubleTip
				title='Passione'
				description='La nostra passione per le piante e la tradizione della medicina naturale e, l’impegno dell’azienda sono rivolti a mantenere e a sviluppare questo equilibrio delicato e benefico tra l’uomo e la sua natura circostante.'
			/>
			<BubleTip
				title='Provenienza'
				description='Formulanatura produce i suoi integratori alimentari ponendo una particolare attenzione sulle materie prime utilizzate, molte delle quali provenienti da agricoltura biologica le altre da fornitori selezionati in base a criteri di alta qualità.'
			/>
			<BubleTip
				title='Certificazione'
				description='Formulanatura opera con sistema di Qualità Certificata ISO 9001-2008 da diversi anni e attua rigorosamente le norme HACCP salvaguardando tutta la filiera produttiva e commerciale. '
			/>
			<BubleTip
				title='Sicurezza'
				description='La Sicurezza e la Qualità rappresentano gli obiettivi principali del nostro operare al fine di essere in linea con la competitività dei mercati e le giuste esigenza dei consumatori più attenti e informati che ci stimolano a migliorarci continuamente'
			/>{" "}
			<BubleTip
				title='Certiquality'
				description='È un Organismo al servizio delle imprese accreditato per la certificazione dei sistemi di gestione aziendale per la qualità, l’ambiente, la sicurezza e nella certificazione di prodotto. Certiquality opera inoltre nella verifica della sicurezza alimentare, dei sistemi informativi e nella formazione.'
			/>{" "}
			<BubleTip
				title='CCPB'
				description='È un organismo di controllo e certificazione che deriva la propria attività ed esperienza dal Consorzio per il controllo dei Prodotti Biologici.'
			/>
			<BubleTip
				title='NATRUE'
				description='È un’organizzazione no-profit che si propone di promuovere e tutelare la cura della pelle con prodotti naturali. Si occupa di garantire materie prime di altà qualità per il settore cosmetico accertandone la provenienza da agricoltura biologica e naturalee di certificare che i prodotti finiti rispettino gli standard di questo genere di produzione.'
			/>
			<BubleTip
				title='IASC'
				description='International Aloe Science Council, è un’organizzazione commerciale no-profit, creata per monitorare gli standard dell’industria Aloe Vera in tutto il mondo. È una certificazione di qualità che mira a controllare le strutture e i processi produttivi di Aloe Vera'
			/>
		</div>
	);
}

export default About;
