/** @format */

import React from "react";
import "./HeroBanner.scss";
import logo from "../../logo.svg";
function HeroBanner() {
	return (
		<div className='heroBanner'>
			{/* <img alt='Main Hero Banner' src='https://via.placeholder.com/1920x1080' /> */}
			<div className='leftHeroSection'>
				<h1>Scopri la forza della Natura</h1>
				<p>
					La Sicurezza e la Qualità rappresentano gli obiettivi principali del
					nostro operare al fine di essere in linea con la competitività dei
					mercati e le giuste esigenza dei consumatori più attenti e informati
					che ci stimolano a migliorarci continuamente
				</p>
			</div>
			<img src={logo} />
		</div>
	);
}

export default HeroBanner;
