/** @format */

import React from "react";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Header></Header>
				<Routes>
					<Route path='/' element={<Home />}></Route>
					<Route path='/about' element={<About />}></Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
