/** @format */

import React from "react";
import "./Header.scss";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";
function Header() {
	return (
		<header>
			<div className='logoSection'>
				<img className='' src={logo} alt='logo' />
				<Link to='/'>Formulanatura</Link>
			</div>
			<div className='linksSection'>
				<Link to='/'>home</Link>
				<Link to='/about'>scoprici</Link>
				<p>shop</p>
				<p>contatti</p>
			</div>
		</header>
	);
}
export default Header;
